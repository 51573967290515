import React from "react"
// import { Link } from "gatsby"
import { Layout } from "../components/layout"
import Seo from "../components/seo"
import { Controller, Scene } from 'react-scrollmagic'
import { BrowserView } from 'react-device-detect';

import { MagLinks } from "../components/MagLinks"

const aboutPage = () => {

    const rDatas = {
        "Designer": [
            [
                "仕事内容",
                `
                クライアントが抱える問題や実現したいことをデジタルデザインの視点で解決していきます。<br>
                ディレクターとともにクライアントへのヒアリングや提案、プレゼンテーションを行うことがあります。<br>
                次期アートディレクターへの登用の可能性もあります。
            `
            ],
            [
                "求める人物像",
                `
            • 常に成長しようとする気持ちのある方<br>
            • Webサイトやアプリなどのデジタルプロダクト、テクノロジーに興味・関心がある方<br>
            • 他責にせず自責で仕事を進められる方<br>
            • コミュニケーションを円滑に行える方<br>
            • ブランディングの観点でクライアントにデザイン提案ができる方
            `
            ],
            [
                "求めるスキル",
                `
            • Adobe Illustrator<br>
            • Adobe Photoshop<br>
            • Figma
            `
            ],
            [
                "歓迎するスキル",
                `
            • HTML / CSS / Javascriptのコーディング経験<br>
            • ノーコードデザインツール STUDIOでのWebサイト制作経験<br>
            • blenderでの3Dアニメーション制作経験<br>
            • デザイナーとしての実務経験<br>
            • フリーランスとしての活動経験
            `
            ],
            [
                "選考フロー",
                `
            1. 書類選考<br>
            2. 面接（1〜3回程度）
            `
            ],
            [
                "給与",
                `
            新卒: 年収230万円〜<br>
            中途: 年収290万円〜<br>
            ※面接後、能力により決定
            `
            ],
            [
                "昇給･賞与",
                `
            昇給年1回、賞与年1回
            `
            ],
            [
                "勤務時間",
                `
            10:00〜19:00（休憩1時間）<br>
            ※時間外労働あり
            `
            ],
            [
                "休日･休暇",
                `
            年間休日119日(会社カレンダーに則る／土日、年末年始) 長期休暇（ＧＷ・夏季）、年末年始休暇、有給休暇
            `
            ],
            [
                "福利厚生",
                `
            社会保険完備
            `
            ],
            [
                "応募方法",
                <MagLinks path="https://www.wantedly.com/projects/1691716" outer={true}>
                    <p>Wantedlyから応募する</p>
                </MagLinks>
            ],
        ],
        "Engineer": [
            [
                "仕事内容",
                `
            デザイナーとともにWebサイト・アプリを設計・構築していきます。<br>
            ユーザーへのUXを担保するためにフロントエンドの高い技術力と表現力が必要になります。<br>
            次期テクニカルディレクターへの登用の可能性もあります。
            `
            ],
            [
                "求める人物像",
                `
            • 常に成長しようとする気持ちのある方<br>
            • Webサイトやアプリなどのデジタルプロダクト、テクノロジーに興味・関心がある方<br>
            • 他責にせず自責で仕事を進められる方<br>
            • コミュニケーションを円滑に行える方<br>
            • デザイナーの表現に対して否定せず、最善策を一緒に考えることができる方
            `
            ],
            [
                "求めるスキル",
                `
            • HTML<br>
            • CSS（SCSS）<br>
            • JavaScript（Vanilla JS）<br>
            • PHP
            `
            ],
            [
                "歓迎するスキル",
                `
            • React / React Native<br>
            • Astro<br>
            • three.jsでの3Dアニメーション開発経験<br>
            • WordPressでのオリジナルテーマ開発経験<br>
            • ノーコードデザインツール STUDIOでのWebサイト制作経験<br>
            • エンジニアとしての実務経験<br>
            • フリーランスとしての活動経験
            `
            ],
            [
                "選考フロー",
                `
            1. 書類選考<br>
            2. 面接（1〜3回程度）
            `
            ],
            [
                "給与",
                `
            新卒: 年収230万円〜<br>
            中途: 年収290万円〜<br>
            ※面接後、能力により決定
            `
            ],
            [
                "昇給･賞与",
                `
            昇給年1回、賞与年1回
            `
            ],
            [
                "勤務時間",
                `
            10:00〜19:00（休憩1時間）<br>
            ※時間外労働あり
            `
            ],
            [
                "休日･休暇",
                `
            年間休日119日(会社カレンダーに則る／土日、年末年始) 長期休暇（ＧＷ・夏季）、年末年始休暇、有給休暇
            `
            ],
            [
                "福利厚生",
                `
            社会保険完備
            `
            ],
            [
                "応募方法",
                <MagLinks path="https://www.wantedly.com/projects/1710083" outer={true}>
                    <p>Wantedlyから応募する</p>
                </MagLinks>
            ],
        ]
    }
    return (
        <Layout>
            <Seo
                title="Recruit"
                description="DESIGN STUDIO KのRecruitページです。新卒・中途の人材を募集しています。"
            />
            <div className="eyecatch">
                <div className="eyecatch__inner">
                    <h1>
                        <span>Recruit</span>
                    </h1>
                </div>
                <BrowserView>
                    <Controller>
                        <Scene
                            classToggle={[".eyecatch__under", "enter"]}
                            triggerHook="onEnter"
                            triggerElement=".enterContents"
                        // offset={300}
                        >
                            <div className="eyecatch__under">
                                <div className={`mv__underRight`} />
                            </div>
                        </Scene>
                    </Controller>
                </BrowserView>
            </div>
            <div className="about__container enterContents">
                {
                    Object.keys(rDatas).map((key) => <section className="about__containerSec">
                        <div className="about__containerSecBlock">
                            <div className="about__containerSecBlockTtl">
                                <h2>{key}</h2>
                            </div>
                            <div className="about__containerSecBlockTxt">
                                <table className="recruit">
                                    <RTable datas={rDatas[key]} />
                                </table>
                            </div>
                        </div>
                    </section>)
                }
            </div>
        </Layout>
    )
}

export default aboutPage

const RTable = ({ datas }) => {
    let ary = [];

    datas.map((data) => {
        ary.push(
            <tr>
                <td>{data[0]}</td>
                <td>
                    {typeof data[1] === "string" ? <p dangerouslySetInnerHTML={{ __html: data[1] }}></p> : data[1]}
                </td>
            </tr>
        );
    });

    return ary
};
